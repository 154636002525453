import React from "react";
import { Link } from "gatsby";

import Scaffold from "../../../components/portfolio";
import seizeTheDay from "../../../images/seizetheday.png";

const Design = (props) => (
  <Scaffold pathname={props.location.pathname}>
    <h2>Website Design</h2>

    <h3>Seize the Day Life Coaching</h3>

    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <div>
        <div className="browser-mockup">
          <img style={{ maxWidth: "100%", height: 180 }} src={seizeTheDay} />
        </div>
      </div>
    </div>

    <p>
      Seize the Day Life Coaching is the brand name of a Life Coach located in
      Melbourne, Victoria. I made a website for them in Wordpress, with a
      customised theme and several plugins importing content from Facebook. Most
      of our time was spent choosing fonts, colors, and images which would best
      attract the target audience of the business. The website was hosted behind
      Cloudflare to ensure the whole site was performant.
    </p>

    <Link to="/portfolio/design/portfolio/">Next</Link>
  </Scaffold>
);

export default Design;
